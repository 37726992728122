exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-automated-security-for-startups-js": () => import("./../../../src/pages/automated-security-for-startups.js" /* webpackChunkName: "component---src-pages-automated-security-for-startups-js" */),
  "component---src-pages-campaigns-aws-cloud-security-js": () => import("./../../../src/pages/campaigns/aws-cloud-security.js" /* webpackChunkName: "component---src-pages-campaigns-aws-cloud-security-js" */),
  "component---src-pages-campaigns-cloud-security-fintech-js": () => import("./../../../src/pages/campaigns/cloud-security-fintech.js" /* webpackChunkName: "component---src-pages-campaigns-cloud-security-fintech-js" */),
  "component---src-pages-campaigns-cloud-security-mssp-js": () => import("./../../../src/pages/campaigns/cloud-security-mssp.js" /* webpackChunkName: "component---src-pages-campaigns-cloud-security-mssp-js" */),
  "component---src-pages-campaigns-cspm-solution-js": () => import("./../../../src/pages/campaigns/cspm-solution.js" /* webpackChunkName: "component---src-pages-campaigns-cspm-solution-js" */),
  "component---src-pages-campaigns-data-security-js": () => import("./../../../src/pages/campaigns/data-security.js" /* webpackChunkName: "component---src-pages-campaigns-data-security-js" */),
  "component---src-pages-campaigns-iam-security-js": () => import("./../../../src/pages/campaigns/iam-security.js" /* webpackChunkName: "component---src-pages-campaigns-iam-security-js" */),
  "component---src-pages-campaigns-iso-27001-js": () => import("./../../../src/pages/campaigns/iso27001.js" /* webpackChunkName: "component---src-pages-campaigns-iso-27001-js" */),
  "component---src-pages-campaigns-kubernetes-security-js": () => import("./../../../src/pages/campaigns/kubernetes-security.js" /* webpackChunkName: "component---src-pages-campaigns-kubernetes-security-js" */),
  "component---src-pages-campaigns-misconfigurations-js": () => import("./../../../src/pages/campaigns/misconfigurations.js" /* webpackChunkName: "component---src-pages-campaigns-misconfigurations-js" */),
  "component---src-pages-campaigns-secure-cloud-quick-easy-js": () => import("./../../../src/pages/campaigns/secure-cloud-quick-easy.js" /* webpackChunkName: "component---src-pages-campaigns-secure-cloud-quick-easy-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-case-studies-bays-consulting-js": () => import("./../../../src/pages/case-studies/bays-consulting.js" /* webpackChunkName: "component---src-pages-case-studies-bays-consulting-js" */),
  "component---src-pages-case-studies-cloud-data-security-js": () => import("./../../../src/pages/case-studies/cloud-data-security.js" /* webpackChunkName: "component---src-pages-case-studies-cloud-data-security-js" */),
  "component---src-pages-case-studies-smart-fintech-js": () => import("./../../../src/pages/case-studies/smart-fintech.js" /* webpackChunkName: "component---src-pages-case-studies-smart-fintech-js" */),
  "component---src-pages-cloud-security-risk-assessment-js": () => import("./../../../src/pages/cloud-security-risk-assessment.js" /* webpackChunkName: "component---src-pages-cloud-security-risk-assessment-js" */),
  "component---src-pages-comparison-cyscale-vs-lacework-js": () => import("./../../../src/pages/comparison/cyscale-vs-lacework.js" /* webpackChunkName: "component---src-pages-comparison-cyscale-vs-lacework-js" */),
  "component---src-pages-comparison-cyscale-vs-wiz-js": () => import("./../../../src/pages/comparison/cyscale-vs-wiz.js" /* webpackChunkName: "component---src-pages-comparison-cyscale-vs-wiz-js" */),
  "component---src-pages-comparison-cyscale-vs-zscaler-js": () => import("./../../../src/pages/comparison/cyscale-vs-zscaler.js" /* webpackChunkName: "component---src-pages-comparison-cyscale-vs-zscaler-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-events-cloud-security-posture-lessons-js": () => import("./../../../src/pages/events/cloud-security-posture-lessons.js" /* webpackChunkName: "component---src-pages-events-cloud-security-posture-lessons-js" */),
  "component---src-pages-free-trial-js": () => import("./../../../src/pages/free-trial.js" /* webpackChunkName: "component---src-pages-free-trial-js" */),
  "component---src-pages-full-platform-tour-js": () => import("./../../../src/pages/full-platform-tour.js" /* webpackChunkName: "component---src-pages-full-platform-tour-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-playground-js": () => import("./../../../src/pages/playground.js" /* webpackChunkName: "component---src-pages-playground-js" */),
  "component---src-pages-policies-privacy-policy-js": () => import("./../../../src/pages/policies/privacy-policy.js" /* webpackChunkName: "component---src-pages-policies-privacy-policy-js" */),
  "component---src-pages-policies-security-policy-js": () => import("./../../../src/pages/policies/security-policy.js" /* webpackChunkName: "component---src-pages-policies-security-policy-js" */),
  "component---src-pages-policies-terms-of-use-js": () => import("./../../../src/pages/policies/terms-of-use.js" /* webpackChunkName: "component---src-pages-policies-terms-of-use-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-products-ciem-js": () => import("./../../../src/pages/products/ciem.js" /* webpackChunkName: "component---src-pages-products-ciem-js" */),
  "component---src-pages-products-cloud-security-posture-management-js": () => import("./../../../src/pages/products/cloud-security-posture-management.js" /* webpackChunkName: "component---src-pages-products-cloud-security-posture-management-js" */),
  "component---src-pages-products-cnapp-js": () => import("./../../../src/pages/products/cnapp.js" /* webpackChunkName: "component---src-pages-products-cnapp-js" */),
  "component---src-pages-products-dspm-js": () => import("./../../../src/pages/products/dspm.js" /* webpackChunkName: "component---src-pages-products-dspm-js" */),
  "component---src-pages-products-security-knowledge-graph-js": () => import("./../../../src/pages/products/security-knowledge-graph.js" /* webpackChunkName: "component---src-pages-products-security-knowledge-graph-js" */),
  "component---src-pages-request-demo-js": () => import("./../../../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-resources-buyers-guide-cloud-security-solutions-js": () => import("./../../../src/pages/resources/buyers-guide-cloud-security-solutions.js" /* webpackChunkName: "component---src-pages-resources-buyers-guide-cloud-security-solutions-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-resources-top-10-cloud-security-challenges-js": () => import("./../../../src/pages/resources/top-10-cloud-security-challenges.js" /* webpackChunkName: "component---src-pages-resources-top-10-cloud-security-challenges-js" */),
  "component---src-pages-security-for-startups-js": () => import("./../../../src/pages/security-for-startups.js" /* webpackChunkName: "component---src-pages-security-for-startups-js" */),
  "component---src-pages-security-for-startups-program-js": () => import("./../../../src/pages/security-for-startups-program.js" /* webpackChunkName: "component---src-pages-security-for-startups-program-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-use-cases-aws-cloud-security-js": () => import("./../../../src/pages/use-cases/aws-cloud-security.js" /* webpackChunkName: "component---src-pages-use-cases-aws-cloud-security-js" */),
  "component---src-pages-use-cases-azure-cloud-security-js": () => import("./../../../src/pages/use-cases/azure-cloud-security.js" /* webpackChunkName: "component---src-pages-use-cases-azure-cloud-security-js" */),
  "component---src-pages-use-cases-cloud-compliance-and-auditing-js": () => import("./../../../src/pages/use-cases/cloud-compliance-and-auditing.js" /* webpackChunkName: "component---src-pages-use-cases-cloud-compliance-and-auditing-js" */),
  "component---src-pages-use-cases-cloud-data-security-js": () => import("./../../../src/pages/use-cases/cloud-data-security.js" /* webpackChunkName: "component---src-pages-use-cases-cloud-data-security-js" */),
  "component---src-pages-use-cases-cloud-misconfigurations-js": () => import("./../../../src/pages/use-cases/cloud-misconfigurations.js" /* webpackChunkName: "component---src-pages-use-cases-cloud-misconfigurations-js" */),
  "component---src-pages-use-cases-cloud-native-security-js": () => import("./../../../src/pages/use-cases/cloud-native-security.js" /* webpackChunkName: "component---src-pages-use-cases-cloud-native-security-js" */),
  "component---src-pages-use-cases-cloud-security-fintech-js": () => import("./../../../src/pages/use-cases/cloud-security-fintech.js" /* webpackChunkName: "component---src-pages-use-cases-cloud-security-fintech-js" */),
  "component---src-pages-use-cases-cloud-security-mssp-js": () => import("./../../../src/pages/use-cases/cloud-security-mssp.js" /* webpackChunkName: "component---src-pages-use-cases-cloud-security-mssp-js" */),
  "component---src-pages-use-cases-gcp-cloud-security-js": () => import("./../../../src/pages/use-cases/gcp-cloud-security.js" /* webpackChunkName: "component---src-pages-use-cases-gcp-cloud-security-js" */),
  "component---src-pages-use-cases-iam-security-js": () => import("./../../../src/pages/use-cases/iam-security.js" /* webpackChunkName: "component---src-pages-use-cases-iam-security-js" */),
  "component---src-pages-use-cases-iso-27001-compliance-js": () => import("./../../../src/pages/use-cases/iso-27001-compliance.js" /* webpackChunkName: "component---src-pages-use-cases-iso-27001-compliance-js" */),
  "component---src-pages-use-cases-kubernetes-security-platform-js": () => import("./../../../src/pages/use-cases/kubernetes-security-platform.js" /* webpackChunkName: "component---src-pages-use-cases-kubernetes-security-platform-js" */),
  "component---src-pages-use-cases-nist-compliance-js": () => import("./../../../src/pages/use-cases/nist-compliance.js" /* webpackChunkName: "component---src-pages-use-cases-nist-compliance-js" */),
  "component---src-pages-use-cases-okta-cyscale-integration-js": () => import("./../../../src/pages/use-cases/okta-cyscale-integration.js" /* webpackChunkName: "component---src-pages-use-cases-okta-cyscale-integration-js" */),
  "component---src-pages-use-cases-pci-dss-js": () => import("./../../../src/pages/use-cases/pci-dss.js" /* webpackChunkName: "component---src-pages-use-cases-pci-dss-js" */),
  "component---src-pages-use-cases-remote-work-security-js": () => import("./../../../src/pages/use-cases/remote-work-security.js" /* webpackChunkName: "component---src-pages-use-cases-remote-work-security-js" */),
  "component---src-pages-use-cases-soc-2-js": () => import("./../../../src/pages/use-cases/soc-2.js" /* webpackChunkName: "component---src-pages-use-cases-soc-2-js" */),
  "component---src-pages-use-cases-vulnerability-management-js": () => import("./../../../src/pages/use-cases/vulnerability-management.js" /* webpackChunkName: "component---src-pages-use-cases-vulnerability-management-js" */),
  "component---src-pages-whitepaper-cloud-storage-misconfigurations-js": () => import("./../../../src/pages/whitepaper/cloud-storage-misconfigurations.js" /* webpackChunkName: "component---src-pages-whitepaper-cloud-storage-misconfigurations-js" */),
  "component---src-pages-whitepaper-the-complete-guide-to-cloud-compliance-js": () => import("./../../../src/pages/whitepaper/the-complete-guide-to-cloud-compliance.js" /* webpackChunkName: "component---src-pages-whitepaper-the-complete-guide-to-cloud-compliance-js" */),
  "component---src-template-author-page-template-js": () => import("./../../../src/template/authorPageTemplate.js" /* webpackChunkName: "component---src-template-author-page-template-js" */),
  "component---src-template-blog-all-posts-template-js": () => import("./../../../src/template/blogAllPostsTemplate.js" /* webpackChunkName: "component---src-template-blog-all-posts-template-js" */),
  "component---src-template-blog-categories-template-js": () => import("./../../../src/template/blogCategoriesTemplate.js" /* webpackChunkName: "component---src-template-blog-categories-template-js" */),
  "component---src-template-blog-template-js": () => import("./../../../src/template/blogTemplate.js" /* webpackChunkName: "component---src-template-blog-template-js" */),
  "component---src-template-career-template-js": () => import("./../../../src/template/careerTemplate.js" /* webpackChunkName: "component---src-template-career-template-js" */),
  "component---src-template-closed-career-template-js": () => import("./../../../src/template/closedCareerTemplate.js" /* webpackChunkName: "component---src-template-closed-career-template-js" */),
  "component---src-template-pages-template-js": () => import("./../../../src/template/pagesTemplate.js" /* webpackChunkName: "component---src-template-pages-template-js" */),
  "component---src-template-prospect-pages-template-js": () => import("./../../../src/template/prospectPagesTemplate.js" /* webpackChunkName: "component---src-template-prospect-pages-template-js" */)
}

